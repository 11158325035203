<template>
  <div class="overlayer">
    <b-input
      class="searchBar"
      v-model="autoSearch"
      placeholder="Suche..."
    ></b-input>
    <img v-if="isLoading" class="searchLoading" src="@/assets/loading.gif" />
    <div class="closeBtn" @click="close"><b-icon-x></b-icon-x></div>
    <div
      v-if="(Object.keys(results).length <= 0) & (isLoading == false)"
      class="noResults"
    >
      Kein(e) Ergebniss(e) gefunden<br /><br />
      <img
        src="https://media.tenor.com/tp268nPETE8AAAAj/milk-and-mocha-cute.gif"
      />
    </div>

    <div v-if="Object.keys(results).length > 0" class="results">
      <div class="resultCard">
        <div class="resultTitle">
          <h4>Aufträge</h4>
          <p>{{ results.jobs.length }} Ergebniss(e)</p>
        </div>
        <div class="resultItems" v-for="job in results.jobs" :key="job.id">
          <div class="resultItem">
            <b-row class="resultItemRow" :style="{'background-color':job.color, 'color':(job.color == 'yellow' ? 'black' : 'white')}">
              <b-col md="1">{{ job.id }}</b-col>
              <b-col md="1">{{ job.mediator }}</b-col>
              <b-col md="3">{{ job.type.replaceAll("&amp;uuml;", "ü").replaceAll("&amp;ouml;", "ö").replaceAll("&amp;auml;", "ä").replaceAll("&amp;szlig;", "ß") }}</b-col>
              <b-col md="2"
                >{{ job.customerName }}<br />{{ job.zipCode }}
                {{ job.city }}</b-col
              >
              <b-col
                >{{ job.date.substring(8, 10) }}.{{
                  job.date.substring(5, 7)
                }}.{{ job.date.substring(0, 4) }} -
                {{ job.start.substring(0, 5) }}</b-col
              >
              <b-col>{{ job.monteurName }}</b-col>
              <b-col md="1"
                ><a
                  class=""
                  :href="'/editContract/' + job.id"
                  style="
                    text-decoration: none;
                    color: white;
                    font-size: 1.7rem;
                    margin-top: 0.3rem;
                  "
                  ><b-icon-pen></b-icon-pen></a
              ></b-col>
            </b-row>
            <hr />
          </div>
        </div>
      </div>
      <div v-if="this.$user.admin == 2" class="resultCard">
        <div class="resultTitle">
          <h4>Buchhaltung</h4>
          <p>0 Ergebniss(e)</p>
        </div>
      </div>
      <div v-if="this.$user.admin == 2" class="resultCard">
        <div class="resultTitle">
          <h4>Monteure</h4>
          <p>0 Ergebniss(e)</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var instance;
export default {
  data: function () {
    return {
      isLoading: false,
      autoSearch: "",
      results: {},
      searchTimeout: undefined,
    };
  },
  methods: {
    close: function () {
      instance.$root.search = false;
    },
  },
  watch: {
    autoSearch: function (val, oldVal) {
      if (this.searchTimeout != undefined) {
        clearTimeout(this.searchTimeout);
      }
      if (val.length < 2) {
        return;
      }
      this.searchTimeout = setTimeout(function () {
        instance.isLoading = true;
        instance.$root.apiRequest("/search", { query: val }, (response) => {
          instance.isLoading = false;
          instance.results = response.data.result;
        });
      }, 300);
    },
  },
  mounted() {
    instance = this;
  },
};
</script>

<style scoped>
.overlayer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  text-align: center;
}
.searchBar {
  background: rgba(0, 0, 0, 0.3);
  color: white;
  width: 50vw;
  margin: 0;
  position: absolute;
  top: 10%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.results {
  width: 50vw;
  margin: 0;
  position: absolute;
  top: 15%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -0%);
  text-align: left;
  color: white;
}
.resultCard {
  padding: 0.5rem 1rem;
  border: 2px;
  border-style: solid;
  margin-top: 1rem;
  max-height: 80vh;
  overflow: scroll;
}
.resultItemRow {
  margin-top: 1rem;
}

.searchLoading {
  height: 20rem;
  filter: invert(1);
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.closeBtn {
  color: white;
  position: absolute;
  top: 2%;
  right: 2%;
  font-size: 3rem;
}
.noResults {
  color: white;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
}
</style>