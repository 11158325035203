<template>
  <div class="systemInfo">
    System mit<br />
    {{ this.$serverUrl().includes("/backend") ? "Produktiv" : "Entwicklungs" }}
    <br />Datenbank
  </div>
</template>

<script>
export default {};
</script>

<style>
.systemInfo {
  position: fixed;
  top: 3rem;
  left: 1rem;
  background-color: red;
  padding: 1rem;
  border-radius: 0.5rem;
  color: white;
  text-align: center;
  font-size:  1.5rem;
}
</style>