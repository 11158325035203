<template>
  <span v-if="Date.parse(eol) >= Date.now() || eol == undefined" style="  display: inline-block; min-width: 10px; padding: 3px 7px; font-size: 12px; color: #fff; background-color: #ccc; border-radius: 10px; vertical-align: middle; line-height: 1;margin-top: -15px;margin-left: 3px;background: #EE7200;font-weight: normal;" class="badge">{{ (eol == undefined ? "*" : "") }}Neu</span>
</template>

<script>
export default {
    name: "NewBadge",
    props: {
        eol: String
    }
}
</script>

<style>

</style>