<template>
  <div class="overlayer">
    <div v-if="$root.requestProcessing.error == false">
      <h3 class="infoText">Anfrage l&auml;uft...</h3>
      <img class="loading" src="@/assets/loading.gif" />
    </div>
    <div v-if="$root.requestProcessing.error == true">
      <h3 class="infoText" style="color: red">
        Die Antwort vom Server konnte nicht verarbeitet werden...
      </h3>

      <h4 class="errorInfo">Fehlerbereicht:</h4>
      <div class="errorText">
        Angefragte URL: {{ this.$root.requestProcessing.serverUrl }}<br /><br /><br />
        Antwort vom Server:<br /><br />
        {{ this.$root.requestProcessing.serverAnswer }}
      </div>
      <!--<textarea disabled class="errorText" v-model="message"> </textarea>-->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message:
        "Angefragte URL: " +
        this.$root.requestProcessing.serverUrl +
        "\n\n\n\nAntwort vom Server:\n\n" +
        this.$root.requestProcessing.serverAnswer,
    };
  },
};
</script>

<style scoped>
.errorText {
  text-align: left;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  color: red;
  padding: 1rem;
  width: 40vw;
  height: 20vw;
  background: rgba(255, 255, 255, 1);
  overflow:auto;
}
.infoText {
  color: white;
  margin-top: 7%;
}
.errorInfo {
  color: white;
  margin-top: 2%;
}
.overlayer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000000000;
  text-align: center;
}

.loading {
  height: 20rem;
  filter: invert(1);
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
</style>