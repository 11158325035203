<template>
  <span style="  display: inline-block; min-width: 10px; padding: 1px 2px; font-size: 12px; color: #fff; background-color: #ccc; border-radius: 10px; vertical-align: middle; line-height: 1;margin-buttom: -15px;margin-left: -9px;background: #EE7200;font-weight: normal;" class="badge">{{ (items == undefined ? "0" : items) }}</span>
</template>

<script>
export default {
    name: "NewBadge",
    props: {
      items: String
    }
}
</script>

<style>

</style>