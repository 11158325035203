<template>
  <router-link :to="target">
  <span
    style="
      display: inline-block;
      min-width: 10px;
      padding: 3px 7px;
      font-size: 12px;
      color: #fff;
      background-color: #ccc;
      border-radius: 10px;
      vertical-align: middle;
      line-height: 1;
      margin-top: -5px;
      margin-left: 3px;
      background: green;
      font-weight: normal;
    "
    class="badge"
    ><b-icon-eye></b-icon-eye
  ></span></router-link>
</template>

<script>
export default {
  name: "ViewBadge",
  props: {
    target: {
      type: String,
      default: "#"
    
    }
  },
};
</script>

<style>
</style>