<template>
  <canvas :id="id"></canvas>
</template>

<script>
var instance;
export default {
  name: "LineChart",
  data: function(){
    return {
      id: uuidv4()
    }
  },
  mounted() {
    instance = this;
    var ctx = document.getElementById(this.id).getContext("2d");
    var chart = new Chart(ctx, {
      // The type of chart we want to create
      type: "line",

      // The data for our dataset
      data: {
        //labels: ["KW1", "KW2", "KW3", "KW4", "KW5", "KW6", "KW7", "KW8", "KW9", "KW10", "KW11", "KW12", "KW13", "KW14", "KW15", "KW16", "KW17", "KW18", "KW19", "KW20", "KW21", "KW22", "KW23", "KW24", "KW25", "KW26", "KW27", "KW28", "KW29", "KW30", "KW31", "KW32", "KW33", "KW34", "KW35", "KW36", "KW37", "KW38", "KW39", "KW40", "KW41", "KW42", "KW43", "KW44", "KW45", "KW46", "KW47", "KW48", "KW49", "KW50", "KW51", "KW52"], // x-axis labels
        labels: instance.chartData.labels, // x-axis labels
        datasets: [
          {
            label: "test",
            backgroundColor: "",
            borderColor: "",
            data: instance.chartData.data, // y-axis data
          },
        ],
      },

      // Configuration options go here
      options: {
        tooltips: {
          mode: "index",
        },
        onAnimationComplete: function () {
          var ctx = this.chart.ctx;
          ctx.font = this.scale.font;
          ctx.fillStyle = this.scale.textColor;
          ctx.textAlign = "center";
          ctx.textBaseline = "bottom";

          this.datasets.forEach(function (dataset) {
            dataset.bars.forEach(function (bar) {
              ctx.fillText(bar.value, bar.x, bar.y - 5);
            });
          });
        },
      },
    });
  },
  props: {
    chartData: Object,
  },
};
function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}
</script>

<style>
</style>
